<template>
    <div class="input_group d_flex_col w-100 mr-10" v-bind:class="{ active: identifier == label }">
        <label :for="label" :ref="label" v-scroll-reveal.reset="{ delay: 200 }">{{ label }}</label>
        <input
            type="text"
            class="input"
            :name="label"
            :placeholder="placeholder"
            @focus="highlight"
            @blur="unhighlight"
            v-if="!textArea"
        />

        <textarea
            :rows="rows"
            class="input"
            :name="label"
            :placeholder="placeholder"
            @focus="highlight"
            @blur="unhighlight"
            v-else
        ></textarea>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: 'Input Field' },
        placeholder: { type: String },
        identifier: { type: String },
        textArea: { type: Boolean, default: false },
        rows: { type: Number, default: 3 }
    },
    methods: {
        highlight(e) {
            this.$emit('highlighted', e.target.name)
        },

        unhighlight() {
            this.$emit('highlighted', '')
        }
    }
}
</script>

<style lang="scss" scoped>
.input_group {
    width: 100%;
    label {
        font-size: 14px;
        color: white;
    }

    .input {
        background: transparent;
        width: 100%;
        border: 0px;
        border-bottom: 1px solid white;
        padding-top: 14px;
        padding-bottom: 5px;
        font-size: 20px;
        color: white;

        &:focus {
            outline: none;
        }
    }

    ::placeholder {
        color: white;
        opacity: 0.7;
        font-weight: 400;
        font-size: 18px;
    }
}

.active {
    label {
        color: $primary !important;
    }
    .input {
        border-bottom: 1px solid $primary !important;
        color: $primary !important;
    }
    ::placeholder {
        color: $primary;
        opacity: 0.7;
    }
}
</style>
